import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';
import classNames from 'classnames';
import RestartPopup from '../../components/general/RestartPopup';
import DetailsTableUser from '../../components/general/DetailsTableUser';
import Filters from '../../components/general/Filters';
import Header from '../../components/ui/Header';
import CalculationLayout from '../../components/layouts/CalculationLayout';

// @ts-ignore
import { ReactComponent as CloseIcon } from '../../assets/vectors/CloseIcon.svg';
import {
  CREATE_BUSINESS,
  CREATE_RESIDENCE,
  GET_SITEMAP_REGION,
} from '../../apollo/queries';
import {
  getBusinessId,
  getResidenceId,
  getUserType,
  setBusinessId,
  setResidenceId,
  setRegionPostalCode,
} from '../../utils/localStorage/localStorageFunctions';
import { getActiveResultFiltersSS } from "../../utils/sessionStorage/sessionStorageFunctions";
import { FiltersType } from "../../utils/sessionStorage/sessionStorageTypes";
import { getCalculationInput } from '../../utils/calculationUtils';
import {
  CreateResidence,
  CreateResidenceVariables,
} from '../../apollo/__generated__/CreateResidence';
import { PlanFeatureFilters, StepUrl, TARIFF_TYPE } from '../../utils/enums';
import { navigateAndSetActiveStep } from '../../utils/utils';
import {
  CreateBusiness,
  CreateBusinessVariables,
} from '../../apollo/__generated__/CreateBusiness';
import ErrorPopup from '../../components/general/ErrorPopup';
import usePreloadPageData from '../../hooks/usePreloadPageData';

import SEOLayout from '../../components/layouts/SEOLayout';
import {
  SitemapRegion,
  SitemapRegionVariables,
} from '../../apollo/__generated__/SitemapRegion';
import NotFoundPage from '../../pages/404';
import EnergyPlanList, {
  IEnergyPlanListData,
  INITIAL_ENERGY_PLAN_LIST_DATA,
} from '../../components/ui/EnergyPlanList';
import Typography from '../../components/ui/Typography';

const style = require('../../pages/Results.module.scss');

type TextContent = 'text1Content' | 'text2Content' | 'text3Content';

const RegionResults = ({ location, pageContext }: any) => {
  const { regionData: ssrRegionData } = pageContext;
  const resendQueries = location?.state?.resendQueries;
  const resetFilters = location?.state?.resetFilters;

  const region = location?.pathname?.split('/')[3] || '';
  const keywordUri = location?.pathname?.split('/')[2] || '';

  const [residenceOrBusinessId, setResidenceOrBusinessId] = useState('');
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [activeTextContent, setActiveTextContent] = useState('');
  const [listData, setListData] = useState<IEnergyPlanListData>(
    INITIAL_ENERGY_PLAN_LIST_DATA
  );
  const { actualPlan } = listData;

  const afterLoadData = (data: IEnergyPlanListData) => {
    setListData(data);
  };

  const textContents: TextContent[] = [
    'text1Content',
    'text2Content',
    'text3Content',
  ];

  // Use query only when SSR data is not available (development mode)
  const { data: fetchedRegionData, loading: loadingRegion, error: regionError } = useQuery<SitemapRegion, SitemapRegionVariables>(
    GET_SITEMAP_REGION,
    {
      variables: { regionSlug: region, keywordUri },
      skip: !region || !!ssrRegionData, // Skip if we have SSR data
    }
  );

  // Use SSR data if available, otherwise use fetched data
  const regionData = ssrRegionData 
    ? { getSitemapRegion: ssrRegionData }
    : fetchedRegionData;

  const retailer = actualPlan?.plan?.retailer;

  // Initial filters
  const [activeFilters, setActiveFilters] = useState<FiltersType | undefined>(
    () => {
      const activeResultFiltersLS = getActiveResultFiltersSS(location.pathname);

      if (!resetFilters && activeResultFiltersLS) {
        return activeResultFiltersLS;
      }

      return undefined;
    }
  );

  useEffect(() => {
    if (!regionData || loadingRegion) return;

    const planType: string[] = [];
    const planFeature: string[] = [];

    if (regionData.getSitemapRegion?.filterOptions) {
      const filterOptions = regionData?.getSitemapRegion?.filterOptions;
      if (filterOptions.brOption) {
        planType.push(TARIFF_TYPE.BR);
      }

      if (filterOptions.srOption) {
        planType.push(TARIFF_TYPE.SR);
      }

      if (filterOptions.touOption) {
        planType.push(TARIFF_TYPE.TOU);
      }

      planFeature.push(
        ...Object.keys(filterOptions).filter((key) => filterOptions[key])
      );
    }

    if (planType.length === 0) {
      planType.push(TARIFF_TYPE.SR);
    }
    if (planFeature.length === 0) {
      planFeature.push(PlanFeatureFilters.onlyBestOfferPerRetailer);
    }

    setActiveFilters({
      planType,
      planFeature,
    });

    if (regionData.getSitemapRegion?.postalCode) {
      setRegionPostalCode(regionData.getSitemapRegion?.postalCode);
    }
  }, [regionData?.getSitemapRegion?.filterOptions, loadingRegion]);

  const [extraOpenOverlay, setExtraOpenOverlay] = useState('');

  const [isRestartPopupVisible, setIsRestartPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);

  const [createResidence] = useMutation<
    CreateResidence,
    CreateResidenceVariables
  >(CREATE_RESIDENCE);

  const [createBusiness] = useMutation<CreateBusiness, CreateBusinessVariables>(
    CREATE_BUSINESS
  );

  /**
   * Optionally send queries to create Residence or Business
   */
  const createResidenceOrBusiness = () => {
    const residenceIdLS = getResidenceId();
    const businessIdLS = getBusinessId();
    const userTypeLS = getUserType();

    // If not coming from previous steps look for values in LS
    // and go directly to Recommendation
    if (!resendQueries) {
      if (residenceIdLS) {
        setResidenceOrBusinessId(residenceIdLS);
        return;
      }
      if (businessIdLS) {
        setResidenceOrBusinessId(businessIdLS);
        return;
      }
    }

    // If coming from next steps (Plan Info, Almost there), send the mutation
    const calcInput = getCalculationInput();

    // Mutation for Household
    if (userTypeLS === 'household') {
      createResidence({ variables: { residenceData: calcInput } })
        .then((value) => {
          const residenceId = value.data?.createResidence?.newResidence?.id;

          if (!residenceId) return;

          setResidenceId(residenceId);
          setResidenceOrBusinessId(residenceId);
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
        });
      return;
    }

    // Mutation for Business
    if (userTypeLS === 'business') {
      createBusiness({ variables: { businessData: calcInput } })
        .then((value) => {
          const businessId = value.data?.createBusiness?.newBusiness?.id;

          if (!businessId) return;

          setBusinessId(businessId);
          setResidenceOrBusinessId(businessId);
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
        });
    }
  };

  /**
   *
   * Entry point
   *
   */
  useEffect(() => {
    createResidenceOrBusiness();
  }, []);

  //
  // UI
  //
  const toggleExtraOpenOverlay = (overlayName: 'details' | 'filters') => {
    setExtraOpenOverlay((prevState) => {
      if (prevState === overlayName) return '';
      return overlayName;
    });
  };

  const handleEditDetails = () => {
    setIsRestartPopupVisible(true);
  };

  const handleClickAccordion = (text: string) => {
    if (activeTextContent === text) {
      setActiveTextContent('');
    } else {
      setActiveTextContent(text);
    }
  };

  const handleRestart = () => {
    const userType = getUserType();

    if (userType === 'business') {
      navigateAndSetActiveStep(StepUrl.URL_POSTCODE_BUSINESS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
  };

  // TODO: fix IOS Safari
  useEffect(() => {
    const htmlTag = document.getElementsByTagName('html')[0];
    const bodyTag = document.getElementsByTagName('html')[0];
    if (!htmlTag || !bodyTag) return;

    if (extraOpenOverlay) {
      htmlTag.style.overflow = 'hidden';
      bodyTag.style.overflow = 'hidden';
    } else {
      htmlTag.style.overflow = 'visible';
      bodyTag.style.overflow = 'visible';
    }
  }, [extraOpenOverlay]);

  usePreloadPageData(
    StepUrl.URL_POSTCODE_HOUSEHOLD,
    StepUrl.URL_POSTCODE_BUSINESS,
    StepUrl.URL_PLAN_INFO,
    StepUrl.URL_ALMOST_THERE
  );

  // Calculate textHTMLContents directly
  const textHTMLContents = React.useMemo(() => {
    if (!regionData?.getSitemapRegion) return [];
    
    return textContents
      .map((key: TextContent) => regionData.getSitemapRegion?.[key])
      .filter((content): content is string => !!content);
  }, [regionData]);

  if (regionError) return <NotFoundPage notRenderHeader />;

  return (
    <SEOLayout pageTitle={pageContext.title}>
      <CalculationLayout isCalculating={listData.loading}>
        <RestartPopup
          headingText="Edit your details"
          paragraphText="Would you like to edit your details?"
          isRestartPopupVisible={isRestartPopupVisible}
          setIsRestartPopupVisible={setIsRestartPopupVisible}
          onRestart={handleRestart}
        />
        <ErrorPopup
          headingText="Sorry, an error has occurred"
          paragraphText="Would you like to restart your sign-up process?"
          isRestartPopupVisible={isErrorPopupVisible}
          setIsRestartPopupVisible={setIsErrorPopupVisible}
          onRestart={handleRestart}
        />

        <div>
          <Header hasNav/>
          <div className={style.wrapper}>
            <div className={style.leftSection}>
              <div>
                <Media lessThan="desktop">
                  {regionData?.getSitemapRegion?.h1Content ? (
                    <h3 className="my-2">
                      {regionData?.getSitemapRegion?.h1Content}
                    </h3>
                  ) : null}
                </Media>
                <Media greaterThanOrEqual="desktop">
                  {regionData?.getSitemapRegion?.h1Content ? (
                    <h1 className="my-2">
                      {regionData?.getSitemapRegion?.h1Content}
                    </h1>
                  ) : null}
                </Media>
                {regionData?.getSitemapRegion?.h2Content ? (
                  <div
                    className={style.subHeading}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: regionData?.getSitemapRegion?.h2Content,
                    }}
                  />
                ) : null}
                {textHTMLContents.length > 0 && (
                  <div className="accordions">
                    {textHTMLContents.map((value: string, index: Number) => (
                      <div
                        key={`${index}`}
                        className={classNames(
                          {
                            active: activeTextContent === `text${index}`,
                          },
                          'accordionItem'
                        )}
                        onClick={() => handleClickAccordion(`text${index}`)}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: value || '',
                        }}
                      />
                    ))}
                  </div>
                )}
                <Typography className="mt-4 mb-2" tag="h3">
                  There are {listData.totalPlans} plans that meet your
                  selections
                </Typography>
              </div>
              <EnergyPlanList
                hasSolar={regionData?.getSitemapRegion?.solar}
                pathname={location.pathname}
                token={null}
                keywordUri={keywordUri}
                region={region}
                residenceOrBusinessId={residenceOrBusinessId}
                activeFilters={activeFilters}
                allowToLoad={!!regionData}
                onLoadCompleted={afterLoadData}
                onLoadingFilters={setLoadingFilters}
              />
            </div>
            <div className={style.rightSection}>
              <div className={style.yourDetails}>
                <h4>Your details</h4>
                <DetailsTableUser
                  householdSize={
                    regionData?.getSitemapRegion?.householdSize ?? 0
                  }
                  distributorName={
                    regionData?.getSitemapRegion?.supplyArea?.distributor
                      ?.name ?? ''
                  }
                  retailerName={
                    listData.isActualPlanUserSelectedData
                      ? retailer?.orgName
                      : null
                  }
                  postCode={regionData?.getSitemapRegion?.postalCode ?? ''}
                  totalUsage={actualPlan?.annualTotalUsage}
                  controlledLoadUsage={actualPlan?.annualControlledLoad}
                  solarExport={actualPlan?.annualSolarExportKwh}
                  onRestart={() => navigate(`/compare`)}
                />
              </div>

              <Media greaterThanOrEqual="desktop">
                {(mediaClassNames: any, renderChildren: any) => (
                  <aside
                    className={classNames(style.sideMenu, mediaClassNames)}
                  >
                    {renderChildren && (
                      <>
                        <div className={style.filtersSection}>
                          <h4>Filters</h4>
                          {activeFilters && (
                            <Filters
                              setActiveFilters={setActiveFilters}
                              activeFilters={activeFilters}
                              disabled={loadingFilters}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </aside>
                )}
              </Media>
            </div>
          </div>
        </div>
        <Media lessThan="desktop">
          {(mediaClassNames: any, renderChildren: any) => (
            <div className={classNames(style.bottomOverlay, mediaClassNames)}>
              {extraOpenOverlay && renderChildren && (
                <>
                  <div className={style.bottomOverlayHeading}>
                    {extraOpenOverlay === 'details' && (
                      <h4 style={{ marginBottom: '30px' }}>Your details</h4>
                    )}
                    {extraOpenOverlay === 'filters' && <h4>Filters</h4>}
                    <div
                      className={style.bottomOverlayCloseButton}
                      onClick={() => {
                        setExtraOpenOverlay('');
                      }}
                    >
                      <CloseIcon width={24} height={24} />
                    </div>
                  </div>
                  <div className={style.bottomOverlayContent}>
                    {extraOpenOverlay === 'details' && (
                      <DetailsTableUser
                        householdSize={
                          regionData?.getSitemapRegion?.householdSize ?? 0
                        }
                        distributorName={
                          regionData?.getSitemapRegion?.supplyArea?.distributor
                            ?.name ?? ''
                        }
                        retailerName={
                          listData.isActualPlanUserSelectedData
                            ? retailer?.orgName
                            : null
                        }
                        postCode={
                          regionData?.getSitemapRegion?.postalCode ?? ''
                        }
                        totalUsage={actualPlan?.annualTotalUsage}
                        controlledLoadUsage={actualPlan?.annualControlledLoad}
                        solarExport={actualPlan?.annualSolarExportKwh}
                        onRestart={handleEditDetails}
                      />
                    )}
                    {extraOpenOverlay === 'filters' && activeFilters && (
                      <Filters
                        style={{ marginBottom: '15px' }}
                        setActiveFilters={setActiveFilters}
                        activeFilters={activeFilters}
                        disabled={loadingFilters}
                      />
                    )}
                  </div>
                </>
              )}
              <div className={style.bottomOverlayButtons}>
                <div
                  onClick={() => {
                    toggleExtraOpenOverlay('details');
                  }}
                  className={extraOpenOverlay === 'details' ? style.active : ''}
                >
                  Your details
                </div>
                <div
                  onClick={() => {
                    toggleExtraOpenOverlay('filters');
                  }}
                  className={extraOpenOverlay === 'filters' ? style.active : ''}
                >
                  Filters
                </div>
              </div>
            </div>
          )}
        </Media>
      </CalculationLayout>
    </SEOLayout>
  );
};

export default RegionResults;
